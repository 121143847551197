import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { firebaseApp } from "./tracking/firebaseCfg";

const auth = getAuth(firebaseApp);

export function useFirebaseUser() {
  const [user, setUser] = useState(() => auth.currentUser);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const unsuscribe = auth.onIdTokenChanged((user) => {
      if (!isMounted) return;
      setUser(user);
      setHasLoaded(true);
    });
    return () => {
      isMounted = false;
      unsuscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  return { user, hasLoaded };
}

export function useFirebaseId(overrideId?: string) {
  const { user } = useFirebaseUser();
  return overrideId ?? user?.uid ?? "";
}
