import { initializeApp } from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import axios from "axios";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAQyou5KmHpLekDVAJ1qYA9kPMdaFhqPbU",
  authDomain: "bioarchives-db1b4.firebaseapp.com",
  projectId: "bioarchives-db1b4",
};
export const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const visitors = collection(db, "visitors");
export const addVisitor = async () => {
  const json = (
    await axios.get(
      "https://geolocation-db.com/json/a71be890-9957-11ea-be66-c93168939237"
    )
  ).data;
  const { country_name, latitude, longitude, state, city, postal } = json;
  const ip =
    json.IPv4 !== undefined
      ? json.IPv4
      : json.IPv6 !== undefined
      ? json.IPv6
      : "undefined";
  return await addDoc(visitors, {
    country_name,
    latitude,
    longitude,
    city,
    ip,
    postal,
    state,
    referrer: document.referrer ?? "",
    visitedMs: Date.now(),
    visitedOn: new Date(Date.now()).toLocaleString("en-GB", {
      timeZone: "America/Mexico_City",
    }),
  });
};
