import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "../components/Loadable";
import Loader from "../components/Loader";
import ProtectedRouteHOC from "./ProtectedRouteHOC";

const LOAD_DELAY = 500;

const Home = Loadable({
  loader: () => import("../views/Home"),
  Fallback: Loader,
  delay: LOAD_DELAY,
});
const Login = Loadable({
  loader: () => import("../views/Login"),
  Fallback: Loader,
  delay: LOAD_DELAY,
});
const Analytics = Loadable({
  loader: () => import("../views/Analytics"),
  Fallback: Loader,
  delay: LOAD_DELAY,
});

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <ProtectedRouteHOC
          exact
          path="/analytics"
          fallback="/"
          component={Analytics}
        />
      </Switch>
    </Router>
  );
}
