import { useFirebaseUser } from "../firebase";

const propTypes = {};

const defaultProps = {};

type Props = {
  children: any;
};

export default function AuthIsLoaded({ children }: Props) {
  const { hasLoaded } = useFirebaseUser();
  return hasLoaded ? <>{children}</> : <div>loading :)</div>;
}

AuthIsLoaded.propTypes = propTypes;
AuthIsLoaded.defaultProps = defaultProps;
