import { lazy, Suspense } from "react";
import Loader from "./Loader";

type Props = {
  delay?: number;
  loader: Parameters<typeof lazy>[0];
  Fallback?: (val: any) => JSX.Element;
};

// const LOAD_DELAY = 500;

export default function Loadable({
  // delay = LOAD_DELAY,
  loader,
  Fallback = Loader,
}: Props) {
  return () => {
    const Component = lazy(() => loader());
    //  Promise.all([
    //    loader(),
    //    new Promise((resolve) => setTimeout(resolve, delay)),
    //  ]).then(([moduleExports]) => {
    //   setIsLoaded(true);
    //   return moduleExports
    // })
    return (
      <Suspense fallback={<Fallback />}>
        <Component />
      </Suspense>
    );
  };
}
