import React from "react";
import "./App.css";
import store from "./store";
import { Provider } from "react-redux";
import * as dotenv from "dotenv";
import AuthIsLoaded from "./routing/AuthIsLoaded";
import AppRouter from "./routing/AppRouter";

dotenv.config();

function App() {
  return (
    <Provider store={store}>
      <AuthIsLoaded>
        <AppRouter />
      </AuthIsLoaded>
    </Provider>
  );
}

export default App;
