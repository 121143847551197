import {
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { useFirebaseId } from "../firebase";

type Props = {
  component: React.FC;
  exact?: boolean;
  path: string;
  fallback?: string;
};

const ProtectedRouteHoc = (props: RouteComponentProps<{}> & Props) => {
  const { component: Component, fallback, ...rest } = props;
  const firebaseId = useFirebaseId();
  const isLoggedIn = firebaseId !== "";
  return isLoggedIn ? (
    <Route {...rest} render={() => <Component />} />
  ) : (
    <Redirect
      to={{ pathname: fallback ?? "/", state: rest.location.pathname }}
    />
  );
};

export default withRouter(ProtectedRouteHoc);
